<script setup lang="ts">
import type { Promotion, RewardSection } from "@/types";

const { open } = useNlcModals();
const isGuest = useIsGuest();
const { weeklyCashbackData, weeklyCashbackTime, claimIsActive, claimWeeklyReward } = useStatus();
const { t } = useT();
const isCashbackCalculation = useState("isCashbackCalculation");

defineProps<{
	title: string;
	type?: Promotion["type"];
	image: string;
}>();

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("rankLeagueCashback.promo.btn.register");
	}
	if (isCashbackCalculation.value) {
		return t("rankLeagueCashback.promo.btn.calculating");
	}
	return t("rankLeagueCashback.promo.btn.claim");
});

const handleClickInfo = () => {
	if (!weeklyCashbackData.value) {
		return;
	}

	open("LazyOModalVipClubWeeklyReward", {
		sections: weeklyCashbackData.value?.sections as RewardSection[],
		availableAfterDay: weeklyCashbackData.value?.availableAfterDay,
		availableAfterDateTime: weeklyCashbackData.value?.availableAfterDateTime
	});
};

const handleClick = () => {
	if (!claimIsActive.value) {
		return;
	}

	claimWeeklyReward();
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		:buttonName="buttonName"
		:buttonDisabled="(!isGuest && !claimIsActive) || !!isCashbackCalculation"
		:buttonInfo="!isGuest"
		:class="[{ 'card-disabled': (!isGuest && !claimIsActive) || !!isCashbackCalculation }]"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<div class="content flex-center">
			<template v-if="isGuest">
				<AText :size="16" class="subtitle" :modifiers="['bold', 'center']">
					{{ t("Get a percentage of your coins back once a week.") }}
				</AText>
				<div class="weekly-reward flex-center">
					<ASvg name="icons/vip/reward-progress" filled />
					<AText :size="20" as="div" :modifiers="['bold', 'uppercase']">
						<i18n-t keypath="Up to {key}">
							<template #key>
								<span class="color-tertiary-80">{{ t("20%") }}</span>
							</template>
						</i18n-t>
					</AText>
					<ASvg name="icons/vip/reward-progress" class="reverse" filled />
				</div>
			</template>
			<template v-else>
				<div class="weekly-reward flex-center">
					<div class="weekly-reward-item">
						<AText class="color-tertiary-80" :size="28" :modifiers="['bold', 'center']">
							{{ weeklyCashbackData?.currentReward }}%
						</AText>
						<AText :size="10" :modifiers="['bold', 'center', 'uppercase']">
							{{ t("Current") }}
						</AText>
					</div>
					<ASvg v-if="weeklyCashbackData?.nextReward" name="icons/vip/reward-progress" filled />
					<div v-if="weeklyCashbackData?.nextReward" class="weekly-reward-item">
						<AText class="color-custom-6" :size="28" :modifiers="['bold', 'center']">
							{{ weeklyCashbackData.nextReward }}%
						</AText>
						<AText class="color-custom-6" :size="10" :modifiers="['bold', 'center', 'uppercase']">
							{{ t("next status") }}
						</AText>
					</div>
				</div>

				<AText v-if="!weeklyCashbackData?.currentReward" :modifiers="['uppercase', 'bold']">
					<i18n-t keypath="Reach {key} to unlock">
						<template #key>
							<span class="color-tertiary-80">{{ t("Bronze 1") }}</span>
						</template>
					</i18n-t>
				</AText>

				<MCounterBadgeGroup
					v-else-if="!claimIsActive && !isCashbackCalculation"
					:timestamp="weeklyCashbackTime ?? ''"
					:badgeText="t('Claim in')"
				/>

				<AText v-else :modifiers="['uppercase', 'bold', 'center']">
					{{ isCashbackCalculation ? t("rankLeagueCashback.promo.txt1") : t("rankLeagueCashback.promo.txt2") }}
				</AText>
			</template>
		</div>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.card-disabled {
	cursor: default;
}

.content {
	width: 100%;
	height: 124px;
	flex-direction: column;
	justify-content: space-between;

	.weekly-reward {
		padding-left: 10px;
	}
}

.weekly-reward {
	background: linear-gradient(90deg, rgba(99, 0, 188, 0) 0%, #6300bc 46.53%, rgba(99, 0, 188, 0) 100%);
	width: 100%;
	height: 56px;
	gap: 8px;

	:deep(svg) {
		width: 40px;
		height: 20px;
		opacity: 0.4;
	}

	&-item {
		display: flex;
		flex-direction: column;
	}
}

.reverse {
	transform: rotate(180deg);
}
</style>
